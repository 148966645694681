<template>
  <footer v-if="this.$route.name !== 'pageNotFound'" class="footer-landing">
    <div class="footer-landing__container landing-container">
      <div class="footer-landing__top">
        <img alt="Stormberg logo" src="@/assets/icons/footerLogo.svg" style="max-width: 100%">
      </div>
      <div class="footer-landing__bottom">
        <ul class="footer-landing__nav">
          <li class="footer-landing__item">
            <a class="footer-landing__link nav__link_ideas" href="/u/">
              {{ locale.current }}
            </a>
          </li>
          <li class="footer-landing__item">
            <a class="footer-landing__link nav__link_working" href="/history/">
              {{ locale.history }}
            </a>
          </li>
          <li class="footer-landing__item">
            <a class="footer-landing__link nav__link_top" href="/instructions/">
              {{ locale.instructions }}
            </a>
          </li>
          <li class="footer-landing__item" style="margin-right: auto">
            <a class="footer-landing__link nav__link_about" href="/u/personal/">
              {{ locale.settings }}
            </a>
          </li>
          <li class="footer-landing__item">
            <a class="footer__support-link" href="mailto:support@stormberg.io">support@stormberg.io</a>
          </li>
        </ul>
      </div>
      <div class="footer__license">
        <div class="footer__documents">
          <ul class="footer-landing__nav footer-landing__nav_documents">
            <li class="footer-landing__item">
              <a class="footer-landing__link" href="/assets/library/terms_and_conditions.pdf">
                {{ locale.terms }}
              </a>
            </li>
            <li class="footer-landing__item">
              <a class="footer-landing__link">
                {{ locale.privacyPolicy }}
              </a>
            </li>
            <li class="footer-landing__item">
              <a class="footer-landing__link">
                {{ locale.cookiePreferences }}
              </a>
            </li>
          </ul>
        </div>
        <div class="footer__license__wrapper">
          <p class="footer__license-text">
            {{ locale.license }}
          </p>
          <p class="footer__license-text">
            {{ locale.address }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="showCookie" class="cookies">
      <div class="cookies__container">
        <p class="cookies__title">
          {{ locale.cookieUse }}
        </p>
        <p class="cookies__text">
          {{ locale.cookieDescription }}
          <a class="cookies__link" href="#" target="_blank">{{ locale.cookiePolicy }}</a>
        </p>
        <button class="cookies__btn" @click.prevent="acceptCookie">
          {{ locale.cookieAccept }}
        </button>
      </div>
      <img alt="" class="cookies__img" src="@/assets/icons/cookieImage.svg">
    </div>
  </footer>
</template>

<script>
export default {
  name: 'UserFooter',
  data() {
    return {
      showCookie: !localStorage.getItem('useCookie'),
      messages: {
        en: {
          cookieAccept: 'Accept all',
          cookiePolicy: 'Cookies Policy',
          cookieDescription:
            'We use cookies to ensure you get the best experience on our website. To learn more about cookies, including how to control cookies, please read our',
          cookieUse: 'This website uses cookies',
          address: 'Dubai, United Arab Emirates',
          license: 'The entirety of this site is protected by copyright © 2017–2022 Stormberg',
          cookiePreferences: 'Cookies Preferences',
          privacyPolicy: 'Privacy Policy',
          terms: 'Terms and Conditions',
          settings: 'Settings',
          instructions: 'Instructions',
          history: 'Deals history',
          current: 'Current ideas',
        },
        rus: {
          cookieAccept: 'Принять',
          cookiePolicy: 'Политика в отношении файлов cookie',
          cookieDescription:
            'Мы используем файлы cookie, чтобы обеспечить вам наилучший опыт работы на нашем веб-сайте. Чтобы узнать больше о файлах cookie, в том числе о том, как управлять файлами cookie, пожалуйста, ознакомьтесь с нашим',
          cookieUse: 'Этот веб-сайт использует файлы cookie',
          address: 'Дубай, Объединенные Арабские Эмираты',
          license: 'Все права защищены © 2017-2022 Stormberg',
          cookiePreferences: 'Настройки файлов cookie',
          privacyPolicy: 'Политика конфиденциальности',
          terms: 'Условия и положения',
          settings: 'Настройки',
          instructions: 'Инструкции',
          history: 'История сделок',
          current: 'Текущие идеи',
        },
      },
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    locale() {
      return {
        ...this.messages[this.currentLanguage],
      };
    },
  },
  methods: {
    acceptCookie() {
      localStorage.setItem('useCookie', 'true');
      this.showCookie = false;
    },
  },
};
</script>

<style scoped>
.footer__license {
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.footer__license__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__license-text {
  color: #454545;
}

.footer__support-link {
  color: #52A49A;
}

.footer__documents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-landing {
  width: 100%;
  padding: 60px 0;
  background-color: #F8F8F8;
}

.footer-landing__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
}

.footer-landing__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 60px;
  border-bottom: 1px solid #D2D2D2;
}

.footer-landing__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.footer-landing__nav_documents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.footer-landing__nav_documents .footer-landing__link {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.footer-landing__item {
  margin-right: 40px;
}

.footer-landing__item:last-child {
  margin-right: 0;
}

.footer-landing__link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #454545;
  position: relative;
  -webkit-transition: color .2s ease;
  -o-transition: color .2s ease;
  transition: color .2s ease;
}

.footer-landing__link:hover {
  color: #3CAC9E;
}

.footer-landing__link::after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  pointer-events: none;
  height: 2px;
  width: 0;
  background-color: #3CAC9E;
  -webkit-transition: width .2s ease;
  -o-transition: width .2s ease;
  transition: width .2s ease;
}

.footer-landing__link:hover::after {
  width: 100%;
}

.cookies {
  position: fixed;
  left: 20px;
  bottom: 20px;
  right: 20px;
  background: #1e222d;
  -webkit-box-shadow: 0 2px 4px #0006;
  box-shadow: 0 2px 4px #0006;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 600px;
  width: 100%;
  padding: 12px;
  z-index: 100;
  -webkit-transition: opacity .2s ease;
  -o-transition: opacity .2s ease;
  transition: opacity .2s ease;
}

.cookies.hide {
  opacity: 0;
  visibility: hidden;
}

.cookies__title {
  color: #d1d4dc;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 4px;
}

.cookies__text {
  color: #d1d4dc;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 20px;
  margin-right: 15px;
}

.cookies__link {
  color: #26A69A;
}

.cookies__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 87px;
  min-height: 28px;
  color: #fff;
  background-color: #26A69A;
  border-radius: 5px;
  -webkit-transition: background-color .2s ease;
  -o-transition: background-color .2s ease;
  transition: background-color .2s ease;
}

.cookies__btn:hover {
  background-color: #37736c;
}

.cookies__img {
  width: 100px;
  height: 100px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
}

@media (max-width: 991px) {
  .footer__download-link:first-child > svg {
    width: 90px;
    height: 30px;
  }

  .footer__download-link:nth-child(2) > svg {
    width: 103px;
    height: 30px;
  }

  .footer__download-link:last-child > svg {
    width: 88px;
    height: 30px;
  }

  .footer-landing {
    padding: 40px 0 60px;
    position: relative;
  }

  .footer-landing__container {
    padding: 0 20px;

  }

  .footer-landing__nav:not(.footer-landing__nav_documents) {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    list-style-position: inside;
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    width: 100%;
  }

  .footer-landing__item {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .footer-landing__nav_documents .footer-landing__item {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .footer-landing__bottom {
    margin-bottom: 20px;
  }

  .footer__license__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-landing__nav_documents .footer-landing__item:last-child {
    margin-right: 0;
  }

  .footer__license {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .footer__license-text {
    margin-bottom: 20px;
  }

  .footer__license-text:last-child {
    margin-bottom: 0;
  }

  .footer-landing__nav_documents .footer-landing__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .footer-landing__item:last-child {
    margin-bottom: 0;
  }

  .footer-landing__nav_documents {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #E8E8E8;
  }

  .footer-landing__nav_documents .footer-landing__link {
    white-space: nowrap;
  }

  .footer-landing__bottom {
    display: block;
  }

  .footer-landing .footer__license {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .cookies {
    left: 0;
    bottom: 0;
    max-width: none;
  }
}

@media (max-width: 575px) {
  .footer-landing__link {
    font-size: 15px;
  }

  .footer__license-text {
    font-size: 15px;
  }

  .footer-landing__nav:not(.footer-landing__nav_documents) {
    list-style-position: initial;
    -moz-column-count: initial;
    -webkit-column-count: initial;
    column-count: initial;
  }

  .footer-landing__top {
    margin-bottom: 20px;
  }

  .footer-landing__item {
    margin-bottom: 20px;
  }

  .footer-landing__bottom {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  .footer-landing__nav_documents {
    padding-bottom: 0;
    margin-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: none;
  }

  .footer-landing__nav_documents .footer-landing__item {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer-landing__nav_documents .footer-landing__item:last-child {
    margin-bottom: 0;
  }
}
</style>

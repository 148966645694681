import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'main',
    component: async () => import('@/views/PageMain'),
    meta: {
      title: 'Stormberg Investment Research',
      authorizing: false,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/main/',
    name: 'mainMain',
    component: async () => import('@/views/PageMain'),
    meta: {
      title: 'Stormberg Investment Research',
      authorizing: false,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/u/',
    name: 'personal',
    component: async () => import('@/views/PagePersonal'),
    meta: {
      authorizing: true,
      verify: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/u/personal/',
    name: 'personalSettings',
    component: async () => import('@/views/PageSettingsData'),
    meta: {
      authorizing: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/u/personal/notification/',
    name: 'personalNotifications',
    component: async () => import('@/views/PageSettingsNotification'),
    meta: {
      authorizing: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/u/personal/billing/',
    name: 'personalBilling',
    component: async () => import('@/views/PageSettingsBilling'),
    meta: {
      authorizing: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/u/personal/referral/',
    name: 'personalReferral',
    component: async () => import('@/views/PageSettingsReferral'),
    meta: {
      authorizing: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/payments/',
    name: 'payments',
    component: async () => import('@/views/PagePayments'),
    meta: {
      authorizing: true,
      verify: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/history/:user?/',
    name: 'history',
    component: async () => import('@/views/PageHistory'),
    meta: {
      authorizing: true,
      verify: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/detail_ticker/:market/:ticker/:id/',
    name: 'detailTicker',
    component: async () => import('@/views/PageDetailTicker'),
    meta: {
      authorizing: true,
      verify: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/instructions/',
    name: 'instructions',
    component: async () => import('@/views/PageInstructions'),
    meta: {
      authorizing: false,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/confirm/:token',
    name: 'confirmMail',
    component: async () => import('@/views/PageConfirm'),
    meta: {
      authorizing: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/bot/',
    name: 'confirmBot',
    component: async () => import('@/views/PageConfirm'),
    meta: {
      authorizing: true,
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/verify/',
    name: 'verifyMail',
    component: async () => import('@/views/PageConfirmResend'),
    meta: {
      authorizing: true,
      redirectIfVerify: 'personal',
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/auth/:action/',
    name: 'auth',
    component: async () => import('@/views/PageAuth'),
    meta: {
      authorizing: false,
      redirectIfAuthorize: 'personal',
    },
    pathToRegexOptions: { strict: true },
  },
  {
    path: '/:catchAll(.*)',
    name: 'pageNotFound',
    component: async () => import('@/views/PageNotFound'),
    pathToRegexOptions: { strict: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  /* eslint-disable */
  const previewParam = 'previewLocate';

  delete from[previewParam];
  to[previewParam] = from;
  /* eslint-enable */

  if (to.meta.authorizing) {
    if (!store.getters.currentUser.logged && to.name !== 'auth') {
      return next({ name: 'auth', params: { action: 'login' } });
    }
  }
  if (to.meta.verify) {
    if (!store.getters.currentUser.verify && to.name !== 'verifyMail') {
      return next({ name: 'verifyMail' });
    }
  }
  if (to.meta.redirectIfAuthorize && store.getters.currentUser.logged) {
    return next({ name: to.meta.redirectIfAuthorize });
  }
  if (to.meta.redirectIfVerify && store.getters.currentUser.verify) {
    return next({ name: to.meta.redirectIfVerify });
  }
  return next();
});

export default router;

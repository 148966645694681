import setActivityBlock from '@/functions/setActivityBlock';

export default function sendFetch(
  {
    url, before, success, error, options, block, final,
  },
) {
  if (before) before();
  if (block) setActivityBlock(block, 'disable');

  if (url) {
    let finaly = null;
    fetch(url, options ?? {})
      .then(async (response) => {
        finaly = response;
        if (block) setActivityBlock(block);

        if (!response.ok) {
          if (error) {
            try {
              error(await response.json());
            } catch (e) {
              console.log(e);
              error({ detail: 'Internal server error! Please, send message to support!' });
            }
          }
          return false;
        }

        if (success) success(response);
        return true;
      })
      .catch((response) => {
        finaly = response;
        if (error) error(response);
      })
      .finally(() => {
        if (final) final(finaly);
      });
  } else {
    console.log(new Error('sendFetch - require param url'));
  }
}

/* eslint-disable */
export default function setActivityBlock(block, activity) {
  switch (activity) {
    case 'disable':
      block.style.transition = 'opacity .3s';
      block.style.opacity = 0.3;
      block.style.pointerEvents = 'none';
      break;
    default:
      block.style.opacity = '';
      block.style.pointerEvents = '';
      setTimeout(() => {
        block.style.transition = '';
      }, 300);
  }
}

import gsap from 'gsap';
/* eslint-disable */
export default function leave(el, done) {
  const delay = el.dataset.index * 150;
  setTimeout(() => {
    gsap.to(el, {
      opacity: 0,
      height: 0,
      paddingBottom: 0,
      paddingTop: 0,
      marginBottom: 0,
      marginTop: 0,
      duration: 0.3,
      delay: el.dataset.index * 0.15,
      onComplete: () => {
        el.style.paddingTop = '';
        el.style.paddingBottom = '';
        el.style.marginTop = '';
        el.style.marginBottom = '';
        done();
      },
    });
  }, delay);
}

<template>
  <ul class="languages__list">
    <li v-for="country of enableLanguages" :key="country.id + '-act'" class="languages__item">
      <button class="languages__link" @click.prevent="selectCountry(country)">
        <img :src="'/assets/img/countries/' + country.id +'.svg'" alt="" class="languages__link-icon">
        <span class="languages__link-text">
          {{ country.name }}
        </span>
      </button>
    </li>
  </ul>
  <div class="languages__coming">{{ locale.comingSoon }}</div>
  <ul class="languages__list languages__list_disable">
    <li v-for="country of disableLanguages" :key="country.id + '-dis'" class="languages__item">
      <div class="languages__link">
        <img :src="'/assets/img/countries/' + country.id +'.svg'" alt="" class="languages__link-icon">
        <p class="languages__link-text">
          {{ country.name }}
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HeaderLanguages',
  props: ['enableLanguages', 'disableLanguages'],
  methods: {
    selectCountry(country) {
      this.$store.commit('changeLanguage', country.id);
    },
  },
  data() {
    return {
      messages: {
        rus: {
          comingSoon: 'В скором будущем',
        },
        end: {
          comingSoon: 'Coming Soon',
        },
      },
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    locale() {
      return {
        ...this.messages[this.currentLanguage],
      };
    },
  },
  emits: ['updateSelect'],
};
</script>

<style scoped>

.languages__coming {
  font-size: 13px;
  text-align: center;
  margin: auto;
  opacity: .7;
}

.languages__list {
  border-top: 1px solid #E3E3E3;
  padding: 10px 0;
}

.languages__list_disable {
  padding-bottom: 0;
}

.languages__list_disable .languages__link {
  opacity: .3;
  pointer-events: none;
}

.languages__item {
  margin-bottom: 10px;
}

.languages__item:last-child {
  margin-bottom: 0;
}

.languages__link {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  transition: background-color .2s ease;
  width: 100%;
}

.languages__link:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.languages__link:hover {
  background-color: #EAEAEA;
}

.languages__link:active {
  background-color: rgba(234, 234, 234, 0.3);
  outline: none;
}

.languages__link-icon {
  margin-right: 10px;
}

.languages__link-text {
  font-size: 16px;
  line-height: 19px;
  color: #454545;
}

</style>

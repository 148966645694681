export default [
  {
    id: 'china',
    active: false,
    name: '中國人',
    default: false,
  },
  {
    id: 'rus',
    active: true,
    name: 'Русский',
    default: false,
  },
  {
    id: 'en',
    active: true,
    name: 'English',
    default: true,
  },
  {
    id: 'fn',
    active: false,
    name: 'Français',
    default: false,
  },
  {
    id: 'esp',
    active: false,
    name: 'Deutsch',
    default: false,
  },
  {
    id: 'port',
    active: false,
    name: 'Português',
    default: false,
  },
  {
    id: 'dub',
    active: false,
    name: 'عرب',
    default: false,
  },
];

<template>
  <UserHeader></UserHeader>
  <main class="main">
    <router-view/>
    <template v-for="(notice, index) in allNotice" :key="notice.id">
      <PlatformNotice
        :btnFirst="notice.btnFirst" :btnSecond="notice.btnSecond" :description="notice.description"
        :index="index" :status="notice.status"
        :timeout="notice.timeout" :title="notice.title"
        @remove="removeNotice(notice.id)"></PlatformNotice>
    </template>
  </main>
  <UserFooter></UserFooter>
</template>

<script>
// eslint-disable-next-line import/extensions,import/no-absolute-path,import/no-unresolved
import UserHeader from '@/components/headers/UserHeader';
import UserFooter from '@/components/footers/UserFooter';
import PlatformNotice from '@/components/helpers/PlatformNotice';
import Sockets from '@/functions/websocket';
import sendFetch from '@/functions/sendFetch';

export default {
  components: {
    UserHeader,
    UserFooter,
    PlatformNotice,
  },
  computed: {
    allNotice() {
      return Object.values(this.$store.getters.currentNotices);
    },
    loggedUser() {
      const { currentUser } = this.$store.getters;
      return currentUser.logged && currentUser.verify;
    },
  },
  methods: {
    removeNotice(id) {
      this.$store.commit('deleteNotice', id);
    },
    setNewWindowSize() {
      this.$store.commit('refreshWindowSize');
    },
    sendApiPing() {
      setInterval(() => {
        if (this.loggedUser) {
          sendFetch({
            url: '/api/ping',
            options: {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
            },
          });
        }
      }, 61000);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Stormberg Investment Research';
      },
    },
    loggedUser(newValue) {
      if (newValue) {
        if (this.$route.name === 'auth') {
          this.$router.push({ name: 'personal' });
        }
        let proto = 'ws';
        switch (window.location.host) {
          case 'stormberg.io':
          case 'stage.stormberg.io':
            proto = 'wss';
            break;
          default:
        }
        Sockets.setUrl(`${proto}://${window.location.host}/ws`);
        Sockets.connectSocket();
      } else {
        Sockets.closeConnect();
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.setNewWindowSize();
    });
    this.setNewWindowSize();
    this.$store.dispatch('checkUser');
    this.sendApiPing();
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  min-width: 360px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #1F1F1F;
}

body.lock {
  overflow: hidden;
}

*:focus {
  outline: none;
}

picture,
img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ol {
  margin: 0;
  padding: 0 0 0 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.section-title {
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: #1F1F1F;
}

.main__container,
.container {
  max-width: 1220px;
  margin: auto;
  padding: 0 20px;
}

.landing-container {
  max-width: 1640px;
  margin: 0 auto;
  padding: 0 20px;
}

.main__container {
  position: relative;
}

.multiselect__input, .multiselect__single,
.multiselect__tags,
.multiselect {
  min-height: 48px;
}

.notice {
  padding-bottom: 0.75em;
  position: fixed;
  top: 1.5em;
  left: 1.5em;
  width: 25em;
  max-width: calc(100% - 3em);
  transition: transform 0.15s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 1000;
}

.notice__box {
  animation: flyIn 0.3s ease-out;
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.75em;
  box-shadow: 0 0.5em 1em hsla(223, 10%, 10%, 0.1);
  height: 5em;
  transition: background-color 0.15s, color 0.15s;
}

.notice__btns {
  box-shadow: -1px 0 0 hsla(223, 10%, 10%, 0.15);
  flex-direction: column;
  flex-shrink: 0;
  min-width: 6em;
  height: 100%;
  transition: box-shadow 0.15s;
}

.notice__btn {
  background-color: transparent;
  box-shadow: 0 0 0 hsla(223, 10%, 10%, 0.5) inset;
  font-size: 0.7em;
  line-height: 1;
  font-weight: 500;
  height: 100%;
  padding: 0 0.5rem;
  transition: background-color 0.15s, color 0.15s;
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
}

.notice__btn:first-of-type {
  border-radius: 0 0.75rem 0 0;
}

.notice__btn + .notice__btn {
  box-shadow: 0 -1px 0 hsla(223, 10%, 10%, 0.15);
  font-weight: 400;
}

.notice__btn:last-of-type {
  border-radius: 0 0 0.75rem 0;
}

.notice__btn:only-child {
  border-radius: 0 0.75rem 0.75rem 0;
}

.notice__btn-text {
  display: inline-block;
  pointer-events: none;
}

.notice__content {
  padding: 0.375em 1em;
  width: 100%;
  height: 100%;
}

.notice__box, .notice__content {
  align-items: center;
}

.notice__box, .notice__content, .notice__btns {
  display: flex;
}

.notice__icon {
  flex-shrink: 0;
  margin-right: 0.75em;
  width: 2em;
  height: 2em;
}

.notice__icon-picture {
  width: 100%;
  height: auto;
  display: block;
}

.notice__icon-img {
  display: block;
  max-width: 100%;
}

.notice__text {
  line-height: 1.333;
}

.notice__text-title {
  font-size: 0.95em;
  font-weight: bold;
}

.notice__text-subtitle {
  font-size: 0.8em;
  opacity: 0.75;
}

.notice--out .notice__box {
  animation: flyOut .3s ease-out forwards;
}

/*.slide-enter-active, .slide-leave-active {*/
/*  transform-origin: 0 0;*/
/*  transition: transform 5s, height 5s;*/
/*}*/

/*.slide-enter, .slide-leave-to {*/
/*  transform: rotateX(90deg);*/
/*}*/

/*.slide-leave, .slide-enter-to {*/
/*  transform: rotateX(0);*/
/*}*/

.label-site {
  position: relative;
  display: block;
}

.label-site.error .field-site {
  border-color: #ff3a44;
}

.label-site:before {
  content: "";
  display: block;
  position: absolute;
  left: 8px;
  width: 30px;
  height: 30px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}

.field__promo {
  transition: border-color .2s ease;
}

.field__promo:focus {
  border-color: #1DA051;
}

@keyframes flyIn {
  from {
    transform: translateX(calc(-100% - 1.5em));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes flyOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 1.5em));
  }
}

@media (max-width: 991px) {

  .notice {
    width: 22em;
  }

  .notice__text-title {
    font-size: 0.75em;
  }

  .notice__text-subtitle {
    font-size: 0.6em;
  }

  .notice__btns {
    min-width: 4em;
  }

  .notice__btn {
    font-size: 0.6em;
  }

}

@media (max-width: 575px) {
  .main__container,
  .landing-container,
  .container {
    padding: 0 8px;
  }
}
</style>

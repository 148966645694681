import store from '@/store';

class Sockets {
  static socketSubscriptions = [];

  static socketRecords = [];

  static socketMarkets = [];

  static queue = [];

  static countConnection = 0;

  static connectionSuccess = false;

  static socketUrl;

  static socket;

  static establishedNotice = 0;

  static tryConnect = false;

  static setUrl(url) {
    Sockets.socketUrl = url;
  }

  static addQueue(data) {
    Sockets.queue.push(data);
  }

  static async pin() {
    setInterval(() => {
      Sockets.socket.send(JSON.stringify({ action: 'ping' }));
    }, 45000);
  }

  static reConnectSocket() {
    console.log(`ReConnect ${new Date()}`);
    Sockets.connectionSuccess = false;

    if (Sockets.countConnection < 5) {
      Sockets.connectSocket();
    } else {
      Sockets.establishedNotice += 1;
      if (Sockets.countConnection < 15) {
        Sockets.connectSocket();
        store.commit('addNotice', {
          title: 'Connection is not stable!',
          description: 'Page\'s data may not be up to date',
          status: 'warn',
          btnFirst: {
            text: 'Reload page',
            callback: () => {
              window.location.reload();
            },
          },
          btnSecond: {
            text: 'Close',
          },
        });
      } else {
        store.commit('addNotice', {
          title: 'Connection is not stable!',
          description: 'Page\'s data may not be up to date, please, reload the page',
          status: 'error',
          btnFirst: {
            text: 'Reload page',
            callback: () => {
              window.location.reload();
            },
          },
          btnSecond: {
            text: 'Try connect',
            callback: () => {
              Sockets.connectSocket();
            },
          },
          timeout: 0,
        });
      }
    }
  }

  static connectSocket() {
    if (!Sockets.socketUrl) {
      store.commit('addNotice', {
        title: 'Not set connect url!',
        description: 'Please set connect url',
        status: 'warn',
      });
      return;
    }

    if (!Sockets.tryConnect) {
      Sockets.tryConnect = true;
      Sockets.countConnection += 1;

      Sockets.socket = new WebSocket(Sockets.socketUrl);

      Sockets.socket.onopen = () => {
        console.log(`Success connection ${new Date()}`);

        Sockets.pin();
        Sockets.connectionSuccess = true;

        while (Sockets.queue.length > 0) {
          Sockets.sendData(Sockets.queue.shift());
        }
      };

      Sockets.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        store.dispatch('updateCurrentTable', data);
      };

      Sockets.socket.onclose = (event) => {
        console.log(`Close ${new Date()}`);
        Sockets.tryConnect = false;
        if (event.wasClean) {
          Sockets.connectionSuccess = false;
          store.commit('addNotice', {
            title: 'Connection closed',
            description: 'Connection was closed!',
            status: 'warn',
            btnFirst: {
              text: 'Close',
            },
            btnSecond: {
              text: 'Reconnect',
              callback: () => {
                window.location.reload();
              },
            },
            timeout: 0,
          });
        } else {
          Sockets.reConnectSocket();
        }
      };
    }
  }

  static closeConnect() {
    if (Sockets.connectionSuccess) {
      Sockets.tryConnect = false;
      Sockets.socket.close(1000, 'Work ended');
    }
  }

  static sendData(data) {
    if (Sockets.connectionSuccess) {
      Sockets.socket.send(JSON.stringify(data));
    } else {
      Sockets.addQueue(data);
    }
  }
}

export default Sockets;

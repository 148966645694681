import { createApp } from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import VueYandexMetrika from 'vue-yandex-metrika';

createApp(App)
  .use(store)
  .use(router)
  .use(VueYandexMetrika, {
    id: 89683308,
    env: process.env.NODE_ENV,
    router,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  })
  .mount('#app');

import gsap from 'gsap';
/* eslint-disable */
export default function enter(el, done) {
  const prevHeight = el.style.height;
  const prevPosition = el.style.position;
  const prevOpacity = el.style.opacity;
  const prevZIndex = el.style.zIndex;

  el.display = 'block';
  el.style.height = '';
  el.style.position = 'absolute';
  el.style.opacity = 0;
  el.style.zIndex = -1;

  const heightBlock = el.offsetHeight;
  const style = window.getComputedStyle(el);

  const paddingTop = style.getPropertyValue('padding-top');
  const paddingBottom = style.getPropertyValue('padding-bottom');
  const marginTop = style.getPropertyValue('margin-top');
  const marginBottom = style.getPropertyValue('margin-bottom');

  el.display = 'none';
  el.style.height = prevHeight;
  el.style.position = prevPosition;
  el.style.opacity = prevOpacity;
  el.style.zIndex = prevZIndex;
  el.style.paddingTop = '0';
  el.style.paddingBottom = '0';
  el.style.marginTop = '0';
  el.style.marginBottom = '0';

  gsap.to(el, {
    opacity: 1,
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
    marginTop: marginTop,
    marginBottom: marginBottom,
    height: `${heightBlock}px`,
    delay: el.dataset.index * 0.15,
    duration: 0.3,
    onComplete: () => {
      el.style.height = '';
      el.style.paddingTop = '';
      el.style.paddingBottom = '';
      el.style.marginTop = '';
      el.style.marginBottom = '';
      done();
    },
  });
}

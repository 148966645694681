import Vuex from 'vuex';
import sendFetch from '@/functions/sendFetch';
import setActivityBlock from '@/functions/setActivityBlock';
import countries from '@/data/countries';

export default new Vuex.Store({
  state: {
    currentLanguage: null,
    currentUser: {},
    notices: {},
    tradingViewScript: null,
    currentTickerLoaded: false,
    currentDetailTickerLoaded: false,
    currentTickers: {},
    lastDetailTicker: {},
    defaultFilters: {},
    currentWindowSize: 0,
  },
  mutations: {
    addTradingViewScript(state) {
      if (!state.tradingViewScript) {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://s3.tradingview.com/tv.js');
        script.setAttribute('defer', true);
        script.setAttribute('type', 'text/javascript');
        script.id = 'tradingView';
        document.head.appendChild(script);
        state.tradingViewScript = script;
      }
    },
    changeLanguage(state, languageId) {
      state.currentLanguage = languageId;
    },
    userLogin(state, { userEmail, password, callback }) {
      const opt = {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      };
      const mainBlockAuth = document.querySelector('.auth');
      sendFetch({
        url: '/api/login',
        options: {
          ...opt,
          body: JSON.stringify({ user_email: userEmail, password }),
        },
        block: mainBlockAuth,
        error: (response) => {
          this.commit('addNotice', {
            title: 'Error authorize!',
            description: response?.detail,
            status: 'error',
          });
        },
        success: async (response) => {
          this.commit('addNotice', {
            title: 'Successfully authorize!',
            description: 'Start get user data',
            status: 'success',
          });
          const responseJsonMeta = await response.json();
          this.commit('updateUserData', {
            id: responseJsonMeta.userId,
            logged: true,
            name: responseJsonMeta.userName,
            photo: responseJsonMeta.userPhotoPath,
            email: responseJsonMeta.userEmail,
            verify: responseJsonMeta.confirmed,
            acl: responseJsonMeta.acl,
            utc: responseJsonMeta.timezone || 0,
            endSubscribe: responseJsonMeta.subscriptionEndTimestamp || false,
            telegramSubscriber: responseJsonMeta.telegramSubscriber || false,
            userSubscriptionStatus: responseJsonMeta.userSubscriptionStatus || 0,
          });
          callback();
        },
      });
    },
    userRegister(state, { userEmail, userPassword, callback }) {
      const mainBlockAuth = document.querySelector('.auth');
      sendFetch({
        url: '/api/register',
        options: {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            user_email: userEmail,
            password: userPassword,
          }),
        },
        block: mainBlockAuth,
        success: async (response) => {
          setActivityBlock(mainBlockAuth, 'disable');
          this.commit('addNotice', {
            title: 'Success registration!',
            description: 'Verify your email!',
            timeout: 6000,
            status: 'success',
          });
          this.commit('addNotice', {
            title: 'Please, wait',
            description: 'Your has been created. You will be redirected in a moment',
            timeout: 7000,
            status: 'message',
          });

          sendFetch({
            url: '/api/apply_promo_code',
            options: {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                promo_code: 'START7',
              }),
            },
            final: async () => {
              console.log(await this.dispatch('getUserMetaData'));
              setTimeout(() => {
                setActivityBlock(mainBlockAuth, 'active');
                callback(response.status);
              }, 1000);
            },
          });
        },
        error: async (response) => {
          this.commit('addNotice', {
            title: 'Error registration!',
            description: response?.detail,
            status: 'error',
          });
        },
      });
    },
    addNotice(state, notice) {
      let date = new Date();
      let id = `${date.getTime()}${date.getMilliseconds()}`;

      while (state.notices[id]) {
        date = new Date();
        id = `${date.getTime()}${date.getMilliseconds()}`;
      }

      state.notices[id] = {
        ...notice,
        id,
      };
    },
    deleteNotice(state, noticeIndex) {
      setTimeout(() => {
        delete state.notices[noticeIndex];
      }, 400);
    },
    updateUserData(state, {
      id, logged, name, photo, verify, acl, utc, endSubscribe, telegramSubscriber, userSubscriptionStatus,
    }) {
      state.currentUser = {
        id: id || state.currentUser.id,
        logged: logged || state.currentUser.logged,
        name: name || state.currentUser.name,
        photo: photo || state.currentUser.photo,
        verify: verify || state.currentUser.verify,
        acl: acl || state.currentUser.acl,
        utc: utc || state.currentUser.utc,
        endSubscribe: endSubscribe || state.currentUser.endSubscribe,
        telegramSubscriber: telegramSubscriber || state.currentUser.telegramSubscriber,
        userSubscriptionStatus: userSubscriptionStatus || state.currentUser.userSubscriptionStatus,
      };
      localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
    },
    refreshUser(state, json) {
      state.currentUser = json;
    },
    getCurrentTicker(state) {
      sendFetch({
        url: '/api/all_states',
        options: {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
        },
        success: async (response) => {
          state.currentTickers = await response.json();
        },
        error: () => {
          this.commit('addNotice', {
            title: 'Error get table',
            description: 'Please, send message to support!',
            status: 'warn',
          });
        },
        final: () => {
          state.currentTickerLoaded = true;
        },
      });
    },
    getDetailTicker(state, { id }) {
      if (!state.lastDetailTicker[id]) {
        state.lastDetailTicker = {};
        state.currentDetailTickerLoaded = false;
      }
      sendFetch({
        url: '/api/specific_ids',
        options: {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            ids: [id],
            user_data: true,
          }),
        },
        success: async (response) => {
          state.lastDetailTicker = await response.json();
        },
        error: () => {
          if (!state.lastDetailTicker[id]) {
            state.lastDetailTicker = {};
          }
          this.$store.commit('addNotice', {
            title: 'Error get detail idea',
            description: 'Please, send message to support!',
            status: 'error',
          });
        },
        final: () => {
          state.currentDetailTickerLoaded = true;
        },
      });
    },
    updateTickerPrice(state, { name, price }) {
      const convertedName = name.slice(-4) === 'USDT' ? name : `${name}T`;
      const filterCallback = (el) => el.signal.namePreview === convertedName || el.signal.namePreview.replace('-', '') === convertedName;
      Object.values(state.currentTickers).filter(filterCallback).forEach((el) => {
        state.currentTickers[el.signal.id].system.current.price = price;
      });
      Object.values(state.lastDetailTicker).filter(filterCallback).forEach((el) => {
        state.lastDetailTicker[el.signal.id].system.current.price = price;
      });
    },
    updateTickerData(state, { idea }) {
      if (state.currentTickers[idea.signal.id]) {
        state.currentTickers[idea.signal.id].signal = idea.signal;
        state.currentTickers[idea.signal.id].system = idea.system;
      }
      if (state.lastDetailTicker[idea.signal.id]) {
        state.lastDetailTicker[idea.signal.id].signal = idea.signal;
        state.lastDetailTicker[idea.signal.id].system = idea.system;
      }
    },
    newTickerInTable(state, { idea }) {
      this.commit('addNotice', {
        title: `New signal ${idea.signal.namePreview}!`,
        description: 'To main table was added new signal',
        status: 'message',
      });
      state.currentTickers[idea.signal.id] = idea;
    },
    buyTicker(state, { id, price, timestamp }) {
      if (state.currentTickers[id]) {
        state.currentTickers[id].user.buy = { price, timestamp };
      }
      if (state.lastDetailTicker[id]) {
        state.lastDetailTicker[id].user.buy = { price, timestamp };
      }
    },
    sellTicker(state, { id, price, timestamp }) {
      if (state.currentTickers[id]) {
        state.currentTickers[id].user.sell = { price, timestamp };
      }
      if (state.lastDetailTicker[id]) {
        state.lastDetailTicker[id].user.sell = { price, timestamp };
      }
    },
    saveDefaultFilters(state, defaultFilters) {
      state.defaultFilters = defaultFilters;
    },
    refreshWindowSize(state) {
      state.currentWindowSize = window.innerWidth;
    },
  },
  actions: {
    userLogout(state, { callback, withOutFetch = false }) {
      if (!withOutFetch ?? false) {
        sendFetch({
          url: '/api/logout',
          options: {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
            },
          },
          success: () => {
            localStorage.removeItem('currentUser');
            state.dispatch('checkUser');
            callback();
          },
          error: () => {
            this.commit('addNotice', {
              title: 'Error exit',
              description: 'Please, send message to support',
              status: 'error',
            });
          },
        });
      } else {
        localStorage.removeItem('currentUser');
        state.dispatch('checkUser', { withOutFetch });
        callback();
      }
    },
    getUserMetaData(state) {
      sendFetch({
        url: '/api/user_meta',
        options: {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
        },
        success: async (responseMeta) => {
          const responseJsonMeta = await responseMeta.json();
          state.commit('updateUserData', {
            id: responseJsonMeta.userId,
            logged: true,
            name: responseJsonMeta.userName,
            photo: responseJsonMeta.userPhotoPath,
            email: responseJsonMeta.userEmail,
            verify: responseJsonMeta.confirmed,
            acl: responseJsonMeta.acl,
            utc: responseJsonMeta.timezone || 0,
            endSubscribe: responseJsonMeta.subscriptionEndTimestamp || false,
            telegramSubscriber: responseJsonMeta.telegramSubscriber || false,
            userSubscriptionStatus: responseJsonMeta.userSubscriptionStatus || 0,
          });
        },
        final: (resp) => {
          if (resp.status === 401 && state.getters.currentUser.logged) {
            state.dispatch('userLogout', {
              withOutFetch: true,
              callback: () => {
                window.location.reload();
              },
            });
          }
        },
      });
    },
    checkUser(state, { withOutFetch = false } = {}) {
      if (!withOutFetch ?? false) {
        state.dispatch('getUserMetaData');
      }
      const storage = JSON.parse(localStorage.getItem('currentUser'));
      state.commit('refreshUser', storage ?? {
        id: 0,
        logged: false,
        name: null,
        photo: null,
        email: null,
        verify: false,
        acl: [],
        utc: 0,
        endSubscribe: false,
        telegramSubscriber: false,
        userSubscriptionStatus: 0,
      });
    },
    updateCurrentTable(state, {
      action, id, market, name, idea, ticker, price,
    }) {
      switch (action) {
        case 'buy':
          sendFetch({
            url: '/api/buy',
            options: {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                idea_id: id,
                ticker_name: name.replace('-USDT', '-USD'),
                market,
              }),
            },
            error: () => {
              this.commit('addNotice', {
                title: 'Error buy!',
                status: 'error',
                timeout: 4000,
              });
            },
            success: async (resp) => {
              this.commit('addNotice', {
                title: 'Success buy!',
                status: 'success',
                timeout: 4000,
              });
              const respJson = await resp.json();
              this.commit('buyTicker', {
                id,
                price: respJson.actionPrice,
                timestamp: parseInt(respJson.actionTimestamp, 10),
              });
            },
          });
          break;
        case 'sell':
          sendFetch({
            url: '/api/sell',
            options: {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                idea_id: id,
                ticker_name: name.replace('-USDT', '-USD'),
                market,
              }),
            },
            error: () => {
              this.commit('addNotice', {
                title: 'Error sell!',
                status: 'error',
                timeout: 4000,
              });
            },
            success: async (resp) => {
              this.commit('addNotice', {
                title: 'Success sell!',
                status: 'success',
                timeout: 4000,
              });
              const respJson = await resp.json();
              state.commit('sellTicker', {
                id,
                price: respJson.actionPrice,
                timestamp: parseInt(respJson.actionTimestamp, 10),
              });
            },
          });
          break;
        case 'update-price':
          state.commit('updateTickerPrice', { name: ticker, price });
          break;
        case 'update-target':
          this.commit('addNotice', {
            title: `Updated ticker's target for ${idea.signal.namePreview}!`,
            status: 'message',
          });
          state.commit('updateTickerData', { idea });
          break;
        case 'update-status':
          this.commit('addNotice', {
            title: `Updated ticker's status for ${idea.signal.namePreview}!`,
            status: 'message',
          });
          state.commit('updateTickerData', { idea });
          break;
        case 'update-ticker':
          state.commit('newTickerInTable', { idea });
          break;
        default:
      }
    },
  },
  getters: {
    currentTableLoaded(state) {
      return state.currentTickerLoaded;
    },
    detailTickerLoaded(state) {
      return state.currentDetailTickerLoaded;
    },
    windowSize(state) {
      return state.currentWindowSize;
    },
    getDefaultFilters(state) {
      return state.defaultFilters;
    },
    getSubscribeEnded(state) {
      return state.currentUser.endSubscribe;
    },
    lastDetailTicker(state) {
      return state.lastDetailTicker;
    },
    currentTickers(state) {
      return state.currentTickers;
    },
    currentUser(state) {
      return state.currentUser;
    },
    currentNotices(state) {
      return state.notices;
    },
    currentLanguage(state) {
      return state.currentLanguage ?? countries.find((el) => el.default === true).id;
    },
  },
});

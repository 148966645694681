<template>
  <header v-if="this.$route.name !== 'pageNotFound'" class="header sidebar__active">
    <div class="header__container landing-container">
      <div ref="header__menu-burger" :class="{active: showMenuMobile}" class="header__menu-burger menu-burger dropdown">
        <button class="menu-burger__open dropdown__open" @click="toggleMobileMenu">
          <span class="menu-burger__open-wrapper"><span class="menu-burger__open-line"></span></span>
        </button>
        <button class="dropdown__close" @click="toggleMobileMenu">
          <img alt="" class="dropdown__close-icon" src="@/assets/icons/close.svg">
        </button>
        <div class="dropdown__menu">
          <div class="dropdown__background" @click="toggleMobileMenu"></div>
          <div class="dropdown__menu-content">
            <nav class="dropdown__navigation">
              <ul class="dropdown__list">
                <li v-if="!loggedUser" class="header__settings-item languages-settings settings-accor">
                  <button class="languages-settings__open settings-accor__open" @click="toggleMenu">
                    <span class="settings-accor__open-text">{{ selectedCountry.name }}</span>
                    <img alt="" class="settings-accor__open-arrow" src="@/assets/icons/arrow.svg">
                  </button>
                  <transition
                    @enter="enter"
                    @leave="leave"
                    @before-enter="beforeEnter"
                  >
                    <div v-show="selectLanguages" class="settings-accor__full">
                      <button class="languages__active settings-accor__close" @click="toggleMenu">
                      <span class="languages__active-wrapper">
                        <img :alt="selectedCountry.name + ' language'" :src="'/assets/img/countries/' + selectedCountry.id +'.svg'"
                             class="languages__active-icon">
                        <span class="languages__active-text">
                          {{ selectedCountry.name }} </span>
                        <img alt="" class="settings-accor__close-arrow" src="@/assets/icons/arrow_close.svg">
                      </span>
                      </button>
                      <HeaderLanguages :disableLanguages="disableCountries" :enableLanguages="activeCountries"></HeaderLanguages>
                    </div>
                  </transition>
                </li>
                <li v-for="(item, index) of headerMenu" :key="index" class="dropdown__item">
                  <a v-if="item.link" :href="item.link" class="dropdown__link navigation__link_ideas">
                    {{ item.name }}
                  </a>
                  <router-link v-else-if="item.page" :to="{name: item.page}" class="dropdown__link navigation__link_ideas">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </nav>
            <div v-if="!loggedUser" class="dropdown__action">
              <p class="dropdown__action-text">
                {{ locale.getAccount }} -
              </p>
              <router-link :to="{name: 'auth', params: {action: 'register'}, query: {signuptop: '', promo: 'START7'}}"
                           class="header__sign-up header__button">
                <span class="header__button-text">{{ locale.tryForFree }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <router-link :to="{name: 'main'}" class="header__logo">
        <img alt="Logo stormberg" class="header__logo-img" src="@/assets/icons/logo.svg">
      </router-link>
      <nav class="header__navigation navigation">
        <ul class="navigation__list">
          <li v-for="(item, index) of headerMenu" :key="index" class="navigation__item">
            <a v-if="item.link" :href="item.link" class="navigation__link navigation__link_ideas">
              {{ item.name }}
            </a>
            <router-link v-else-if="item.page" :to="{name: item.page}" class="navigation__link navigation__link_ideas">
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </nav>
      <div v-if="currentUser.logged" ref="header__settings" :class="{active: showSetting}" class="header__settings">
        <button class="header__settings-open" @click="toggleSetting">
          <span class="header__account-name">{{ currentUser.name }}</span>
          <span class="header__account-logo">
              <picture class="header__account-picture">
                <img :alt="currentUser.name" :src="currentUser.photo" aria-label="presentation" class="header__account-icon">
              </picture>
              <picture class="header__account-sub_icon">
                <img alt="" src="@/assets/icons/subIconHeaderPhoto.svg" style="display: block">
              </picture>
              </span>
        </button>
        <button class="header__settings-close" @click="toggleSetting">
          <img alt="" class="languages__close-icon" src="@/assets/header/close.svg">
        </button>
        <div class="header__settings-full">
          <div class="dropdown__background" @click="toggleSetting"></div>
          <div class="header__settings-content">
            <ul class="header__settings-list">
              <li class="header__settings-item languages-settings settings-accor">
                <button class="languages-settings__open settings-accor__open" @click="toggleMenu">
                  <span class="settings-accor__open-text">{{ selectedCountry.name }}</span>
                  <img alt="" class="settings-accor__open-arrow" src="@/assets/header/arrow.svg">
                </button>
                <transition
                  @enter="enter"
                  @leave="leave"
                  @before-enter="beforeEnter"
                >
                  <div v-show="selectLanguages" class="settings-accor__full">
                    <button class="languages__active settings-accor__close" @click="toggleMenu">
                      <div class="languages__active-wrapper">
                        <img :alt="selectedCountry.name + 'language'" :src="'/assets/img/countries/' + selectedCountry.id +'.svg'"
                             class="languages__active-icon">
                        <p class="languages__active-text">
                          {{ selectedCountry.name }}
                        </p>
                        <img alt="" class="settings-accor__close-arrow" src="@/assets/header/arrow_close.svg">
                      </div>
                    </button>
                    <HeaderLanguages :disableLanguages="disableCountries" :enableLanguages="activeCountries"></HeaderLanguages>
                  </div>
                </transition>
              </li>
              <li class="header__settings-item">
                <router-link :to="{name: 'personalSettings'}" class="header-notifications__link">
                  <span>{{ locale.myAccount }}</span>
                  <span><img alt="" src="@/assets/icons/myAccount.svg"></span>
                </router-link>
              </li>
              <li class="header__settings-item">
                <router-link :to="{name: 'payments'}" class="header-notifications__link">
                  <span>{{ locale.payments }}</span>
                  <span><img alt="" src="@/assets/icons/myNotifications.svg"></span>
                </router-link>
              </li>
              <li class="header__settings-item">
                <router-link :to="{name: 'personalBilling'}" class="header-notifications__link">
                  <span>{{ locale.enterPromoCode }}</span>
                  <span><img alt="" src="@/assets/icons/myPromocode.svg"></span>
                </router-link>
              </li>
            </ul>
            <div class="header__exit">
              <button class="header__exit-btn" @click="$store.dispatch('userLogout', { callback: () => $router.push({name: 'main'})})">
                <span class="header__exit-btn-text">{{ locale.logOut }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <router-link :to="{name: 'auth', params: {action: 'register'}, query: {signuptop: '', promo: 'START7'}}"
                     class="header__sign-up header__button">
          <span class="header__button-text">{{ locale.tryForFree }}</span>
        </router-link>
        <router-link :to="{name: 'auth', params: {action: 'login'}, query: {login: ''}}" class="header__log-in">
          <span class="header__log-in_desktop">{{ locale.logIn }}</span>
          <span class="header__log-in_mobile">
          <svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg"><path
            clip-rule="evenodd" d="M12.4362 4.4599C13.1136 3.8256 14.0355 3.46667 15 3.46667C15.9645 3.46667 16.8864 3.8256 17.5638
           4.4599C18.2406 5.09372 18.6179 5.95017 18.6179 6.84V9.57778C18.6179 10.4676 18.2406 11.3241 17.5638 11.9579C16.8864
            12.5922 15.9645 12.9511 15 12.9511C14.0355 12.9511 13.1136 12.5922 12.4362 11.9579C11.7594 11.3241 11.382 10.4676
             11.382 9.57778V6.84C11.382 5.95017 11.7594 5.09372 12.4362 4.4599ZM18.6381 13.0054C18.5131 13.1224 18.3825 13.2327
              18.2467 13.3362C19.7284 13.6863 21.1502 14.2676 22.3621 15.0489C24.4507 16.3955 26 18.4004 26 20.9012V21.8978C26
               22.4602 25.7613 22.9964 25.3415 23.3895C24.9223 23.782 24.357 24 23.7707 24H14.748C14.8698 23.5266 14.9494
                23.0363 14.9825 22.5333H23.7707C23.9598 22.5333 24.1381 22.4628 24.2672 22.342C24.3958 22.2216 24.4651
                 22.0615 24.4651 21.8978V20.9012C24.4651 19.0394 23.3202 17.4359 21.5037 16.2647C19.6856 15.0926 17.2788 14.4178
                  15 14.4178C13.6659 14.4178 12.2879 14.6491 11.01 15.076C10.405 14.7249 9.74985 14.4508 9.05786 14.2671C9.90737
                   13.87 10.8184 13.5571 11.7532 13.3362C11.6175 13.2327 11.4868 13.1224 11.3619 13.0054C10.3939 12.0989 9.84715
                    10.8663 9.84715 9.57778V6.84C9.84715 5.55152 10.3939 4.31887 11.3619 3.41239C12.3294 2.5064 13.6383 2 15
                     2C16.3616 2 17.6706 2.5064 18.6381 3.41239C19.6061 4.31887 20.1528 5.55152 20.1528 6.84V9.57778C20.1528
                      10.8663 19.6061 12.0989 18.6381 13.0054Z"
            fill="#454545" fill-rule="evenodd"></path>
          <path clip-rule="evenodd" d="M7.34315 16.9289L11.7071 21.2929C12.0976 21.6834 12.0976 22.3166 11.7071 22.7071L7.34315 27.0711C6.95262
                 27.4616 6.31946 27.4616 5.92893 27.0711C5.53841 26.6806 5.53841 26.0474 5.92893 25.6569L8.58579 23H2C1.44772 23
                  1 22.5523 1 22C1 21.4477 1.44772 21 2 21H8.58579L5.92893 18.3432C5.53841 17.9526 5.53841 17.3195 5.92893
                   16.9289C6.31946 16.5384 6.95262 16.5384 7.34315 16.9289Z" fill="#454545" fill-rule="evenodd"></path></svg></span>
        </router-link>
        <transition
          @enter="enter"
          @leave="leave"
          @before-enter="beforeEnter"
        >
          <div :class="{ active: selectLanguages }" class="header__languages languages">
            <button class="languages__open" @click="toggleMenu">{{ selectedCountry.name }}</button>
            <button class="languages__close" @click="toggleMenu">
              <img alt="" class="languages__close-icon" src="@/assets/icons/close.svg">
            </button>
            <div class="languages__full">
              <div class="dropdown__background"></div>
              <div class="languages__full-content">
                <div class="languages__active">
                  <div class="languages__active-wrapper">
                    <img :alt="selectedCountry.name + 'language'" :src="'/assets/img/countries/' + selectedCountry.id +'.svg'"
                         class="languages__active-icon">
                    <p class="languages__active-text">{{ selectedCountry.name }}</p>
                  </div>
                </div>
                <HeaderLanguages :disableLanguages="disableCountries" :enableLanguages="activeCountries"></HeaderLanguages>
              </div>
            </div>
          </div>
        </transition>
      </template>
    </div>
    <div v-if="!loggedUser" class="dropdown__action">
      <p class="dropdown__action-text">
        {{ locale.getAccount }} -
      </p>
      <router-link :to="{name: 'auth', params: {action: 'register'}, query: {signuptop: '', promo: 'START7'} }"
                   class="header__sign-up header__button">
        <span class="header__button-text">{{ locale.tryForFree }}</span>
      </router-link>
    </div>
  </header>
</template>

<script>
import countries from '@/data/countries';
import HeaderLanguages from '@/components/helpers/HeaderLanguages';
import { mapGetters } from 'vuex';
import beforeEnter from '@/functions/beforeEnter';
import enter from '@/functions/enter';
import leave from '@/functions/leave';

export default {
  name: 'UserHeader',
  components: {
    HeaderLanguages,
  },
  data() {
    return {
      countries,
      selectLanguages: false,
      showMenuMobile: false,
      showSetting: false,
      eventMenu: false,
      eventCurrent: false,
      messages: {
        rus: {
          currentIdeas: 'Текущие идеи',
          history: 'История сделок',
          instructions: 'Инструкции',
          howItWork: 'Как работает платформа',
          aboutUs: 'О нас',
          tryForFree: 'Получить бесплатно',
          getAccount: 'Для получения аккаунта',
          logIn: 'Войти',
          logOut: 'Выйти',
          enterPromoCode: 'Ввести промокод',
          payments: 'Тарифы',
          myAccount: 'Мой аккаунт',
        },
        en: {
          currentIdeas: 'Current ideas',
          history: 'History',
          instructions: 'Instructions',
          howItWork: 'How It Works',
          aboutUs: 'About Us',
          tryForFree: 'Try it for free',
          getAccount: 'To get access to personal account',
          logIn: 'Log in',
          logOut: 'Log out',
          enterPromoCode: 'Enter the promocode',
          payments: 'Payments',
          myAccount: 'My account',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentLanguage: 'currentLanguage', currentUser: 'currentUser',
    }),
    headerMenu() {
      return this.currentUser.logged ? [
        {
          page: 'personal',
          name: this.locale.currentIdeas,
        },
        {
          page: 'history',
          name: this.locale.history,
        },
        {
          page: 'instructions',
          name: this.locale.instructions,
        },
      ] : [
        {
          link: '/#working',
          name: this.locale.howItWork,
        },
        {
          link: '/#about',
          name: this.locale.aboutUs,
        },
      ];
    },
    selectedCountry() {
      if (this.currentLanguage) {
        return countries.find((country) => country.id === this.currentLanguage);
      }

      return this.defaultCountry;
    },
    activeCountries() {
      return countries.filter((country) => country.active && country.id !== this.selectedCountry.id);
    },
    disableCountries() {
      return countries.filter((country) => !country.active);
    },
    defaultCountry() {
      return countries.find((country) => country.default);
    },
    loggedUser() {
      return this.currentUser.logged;
    },
    locale() {
      return {
        ...this.messages[this.currentLanguage],
      };
    },
  },
  watch: {
    loggedUser(newValue) {
      if (newValue) {
        this.setEvents();
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.selectLanguages = false;
        this.showMenuMobile = false;
        this.showSetting = false;
      },
    },
  },
  methods: {
    beforeEnter,
    enter,
    leave,
    setEvents() {
      if (!this.eventCurrent) {
        this.eventCurrent = true;
        document.addEventListener('click', (e) => {
          if (this.showSetting === true && this.$refs.header__settings && !this.$refs.header__settings.contains(e.target)) {
            this.toggleSetting();
          }
        });
      }
      if (!this.eventMenu) {
        this.eventMenu = true;
        document.addEventListener('click', (e) => {
          if (this.showMenuMobile === true && this.$refs['header__menu-burger'] && !this.$refs['header__menu-burger'].contains(e.target)) {
            this.toggleMobileMenu();
          }
        });
      }
    },
    toggleSetting() {
      this.showSetting = !this.showSetting;
    },
    toggleMenu() {
      this.selectLanguages = !this.selectLanguages;
    },
    toggleMobileMenu() {
      this.showMenuMobile = !this.showMenuMobile;
    },
  },
  mounted() {
    this.setEvents();
  },
};
</script>

<style scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff;
}

.header::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(136.35deg, rgba(245, 245, 245, 0.9) 0%, rgba(230, 230, 230, 0.9) 99.27%);
  border-radius: 20px;
}

.header__container {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  min-height: 80px;
}

.header__logo {
  margin-right: auto;
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 3px;
}

.header__logo:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.header__logo:active {
  outline: none;
}

.header__navigation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.navigation__list {
  display: flex;
  height: 100%;
}

.navigation__item {
  height: 100%;
}

.navigation__link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(50, 50, 50, 1);
  width: 100%;
  display: flex;
  padding: 3px 30px;
  align-items: center;
  height: 100%;
  transition: background-color .2s ease;
  position: relative;
  white-space: nowrap;
}

.navigation__link.active {
  pointer-events: none;
}

.navigation__link.active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  height: 2px;
  background: linear-gradient(180deg, #52A49A 26.67%, #0EA652 100%), #D9D9D9;
}

.navigation__link:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.navigation__link:hover {
  background-color: #EAEAEA;
}

.navigation__link:active {
  background-color: rgba(234, 234, 234, 0.3);
  outline: none;
}

.header__sign-up {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-width: 160px;
  background: linear-gradient(180deg, #E7AB13 26.67%, #CF9118 100%);
  /*background: linear-gradient(180deg, #52A49A 26.67%, #0EA652 100%), linear-gradient(180deg, #00866E 0%, #007357 100%);*/
  border-radius: 2px;
  margin-right: 20px;
  min-height: 50px;
  z-index: 0;
  position: relative;
}

.header__button-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  position: relative;
  z-index: 10;
  text-align: center;
}

.header__button {
  position: relative;
}

.header__button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .2s ease;
  /*background: linear-gradient(0deg, rgba(14, 98, 90, 0.5), rgba(14, 98, 90, 0.5)), linear-gradient(180deg, #52A49A 26.67%, #0EA652 100%);*/
  background: linear-gradient(0deg, rgba(129, 0, 0, 0.3), rgba(129, 0, 0, 0.3)), linear-gradient(180deg, #E7AB13 26.67%, #CF9118 100%);
}

.header__button:focus {
  outline: 3px solid #E8E8E8;
}

.header__button:hover::after {
  opacity: 1;
}

.header__button:active {
  outline: none;
}

.header__button:active::after {
  /*background: linear-gradient(0deg, #0E625A, #0E625A), linear-gradient(180deg, #52A49A 26.67%, #0EA652 100%);*/
  background: linear-gradient(0deg, rgba(129, 0, 0, 0.5), rgba(129, 0, 0, 0.5)), linear-gradient(180deg, #E7AB13 26.67%, #CF9118 100%);
}

.header__log-in {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 3px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(50, 50, 50, .5);
  position: relative;
  transition: background-color .2s ease;
}

.header__log-in:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.header__log-in:hover {
  background-color: #EAEAEA;
}

.header__log-in:active {
  background-color: rgba(234, 234, 234, 0.3);
  outline: none;
}

.header__log-in::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
  background-color: #D9D9D9;
}

.languages {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  position: relative;
  z-index: 10;
}

.languages__open {
  height: 100%;
  padding: 3px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(50, 50, 50, .5);
  opacity: 1;
  position: relative;
  transition: background-color .2s ease, opacity .2s ease;
}

.languages.active .languages__open {
  opacity: 0;
  outline: none;
}

.languages__open:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.languages__open:hover {
  background-color: #EAEAEA;
}

.languages__open:active {
  background-color: rgba(234, 234, 234, 0.3);
  outline: none;
}

.languages__full {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 220px;
  border: 1px solid #E3E3E3;
  border-radius: 0 0 3px 3px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease;
}

.languages.active .languages__full {
  opacity: 1;
  visibility: visible;
}

.languages__close {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 3px;
  top: 0;
  left: 0;
  background-color: rgba(234, 234, 234, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, background-color .2s ease;
}

.languages__close:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.languages__close:hover {
  background-color: rgba(234, 234, 234, 1);
}

.languages__close:active {
  background-color: rgba(234, 234, 234, 0.3);
  outline: none;
}

.languages.active .languages__close {
  opacity: 1;
  visibility: visible;
}

.languages__active {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 10px;
  width: 100%;
}

.languages__active-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.languages__active-icon {
  margin-right: 10px;
}

.languages__active-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #454545;
}

.menu-burger {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.menu-burger__open {
  height: 100%;
  padding: 0 20px;
  position: relative;
}

.menu-burger__open-wrapper {
  width: 30px;
  height: 15px;
  position: relative;
  display: block;
}

.menu-burger__open-line,
.menu-burger__open-wrapper::after,
.menu-burger__open-wrapper::before {
  width: 100%;
  height: 2px;
  background-color: #323232;
  position: absolute;
  left: 0;
}

.menu-burger__open-wrapper::after,
.menu-burger__open-wrapper::before {
  content: '';
}

.menu-burger__open-line {
  top: 50%;
  transform: translateY(-50%);
}

.menu-burger__open-wrapper::after {
  top: 0;
}

.menu-burger__open-wrapper::before {
  bottom: 0;
}

.header__subscribe {
  background: linear-gradient(180deg, #52A49A 26.67%, #0EA652 100%);
  border-radius: 2px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  min-width: 180px;
  margin-right: 20px;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.header__subscribe:focus {
  outline: 3px solid #E8E8E8;
}

.header__subscribe:active {
  outline: none;
}

.header__subscribe::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .2s ease;
  background: linear-gradient(0deg, rgba(14, 98, 90, 0.5), rgba(14, 98, 90, 0.5)), linear-gradient(180deg, #52A49A 26.67%, #0EA652 100%);;
}

.header__subscribe:hover::after {
  opacity: 1;
}

.header__subscribe:active::after {
  background: linear-gradient(0deg, #0E625A, #0E625A), linear-gradient(180deg, #52A49A 26.67%, #0EA652 100%);
}

.header__subscribe-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  position: relative;
  z-index: 10;
}

.header__account {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 3px 20px;
  position: relative;
  transition: background-color .2s ease;
}

.header__account.active {
  background-color: rgba(234, 234, 234, 0.5);
  pointer-events: none;
}

.header__account:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.header__account:hover {
  background-color: #EAEAEA;
}

.header__account:active {
  outline: none;
  background-color: rgba(234, 234, 234, 0.3);
}

.header__account::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
  background-color: #D9D9D9;
}

.header__account-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(69, 69, 69, .5);
  margin-right: 20px;
}

.header__account-picture {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
}

.header__account-logo {
  position: relative;
  border: 1px solid #E3E3E3;
  border-radius: 50%;
}

.header__account-sub_icon {
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  outline: 3px solid white;
  border-radius: 10px;
}

.header__account-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.header__settings {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  position: relative;
  z-index: 10;
}

.header__settings-open {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3px 20px;
  height: 100%;
  opacity: 1;
  transition: opacity .2s ease;
}

.header__settings.active .header__settings-open {
  opacity: 0;
  outline: none;
}

.header__settings-open:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.header__settings-open:hover {
  background-color: #EAEAEA;
}

.header__settings-open:active {
  outline: none;
  background-color: rgba(234, 234, 234, 0.3);
}

.header__settings-list {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #E3E3E3;
}

.header__settings-full {
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 0 0 3px 3px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease;
}

.header__settings-content {
  padding: 15px 10px;
  background-color: #F8F8F8;
  min-width: 294px;
}

.header__settings.active .header__settings-full {
  opacity: 1;
  visibility: visible;
}

.header__settings-title {
  padding: 16.5px 0;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #454545;
  margin-bottom: 10px;
}

.settings-accor {
  margin-bottom: 10px;
}

.settings-accor:last-child {
  margin-bottom: 0;
}

.settings-accor__open {
  padding: 15px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  transition: background-color .2s ease;
}

.settings-accor__open:hover {
  background-color: #EAEAEA;
}

.settings-accor__open-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #454545;
}

.settings-accor__open-arrow {
  opacity: .5;
}

.header__exit-btn {
  background-color: #454545;
  border-radius: 3px;
  padding: 12px 20px;
  width: 100%;
  position: relative;
}

.header__exit-btn:focus {
  outline: 3px solid #E8E8E8;
}

.header__exit-btn:active {
  outline: none;
}

.header__exit-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #454545;
  border-radius: 3px;
  opacity: 0;
  transition: opacity .2s ease;
}

.header__exit-btn:hover::after {
  opacity: 1;
}

.header__exit-btn:active::after {
  opacity: 1;
  background: linear-gradient(0deg, #000000, #000000), #454545;
}

.header__exit-btn-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  position: relative;
  z-index: 10;
}

.header__settings-subscribe-more {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.header__settings-subscribe-more,
.header__settings-subscribe-link {
  font-size: 16px;
  line-height: 22px;
  color: rgba(69, 69, 69, .5);
}

.header__settings-subscribe-link {
  text-decoration: underline;
}

.header__settings-subscribe-link:hover {
  text-decoration: none;
}

.header__settings-close {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 3px;
  top: 0;
  left: 0;
  background-color: rgba(234, 234, 234, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, background-color .2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__settings-close:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.header__settings-close:hover {
  background-color: rgba(234, 234, 234, 1);
}

.header__settings-close:active {
  background-color: rgba(234, 234, 234, 0.3);
  outline: none;
}

.header__settings.active .header__settings-close {
  opacity: 1;
  visibility: visible;
}

.settings-accor__full {
  margin-top: -55px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), #F8F8F8;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  padding: 15px 10px 10px 10px;
  position: relative;
  z-index: 10;
}

.settings-accor__close {
  cursor: pointer;
}

.settings-accor__close .languages__active-text {
  margin-right: auto;
}

.header-notifications__close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
}

.header-notifications__close-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #454545;
}

.header-notifications__list {
  padding-top: 15px;
  border-top: 1px solid #E3E3E3;
}

.header-notifications__item {
  margin-bottom: 15px;
}

.header-notifications__item:last-child {
  margin-bottom: 0;
}

.header-notifications__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.header-notifications__label-text {
  font-size: 16px;
  line-height: 22px;
  color: #454545;
  max-width: 180px;
}

.custom-checkbox {
  display: flex;
  width: 32px;
  height: 22px;
  background-color: rgba(248, 248, 248, .5);
  border: 1px solid rgba(69, 69, 69, .5);
  border-radius: 2px;
  padding: 3px;
  transition: background-color .2s ease, border-color .2s ease;
}

.custom-checkbox__check {
  display: flex;
  width: 14px;
  background-color: #454545;
  border-radius: 1px;
  transform: translateX(0);
  transition: transform .2s ease, background-color .2s ease;
}

.header-notifications__label-input:checked + .custom-checkbox {
  background-color: #fff;
  border-color: #0EA652;
}

.header-notifications__label-input:checked + .custom-checkbox .custom-checkbox__check {
  transform: translateX(10px);
  background-color: #0EA652;
}

.menu-burger {
  display: none;
}

.dropdown {
  position: relative;
  z-index: 10;
}

.dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown__open {
  height: 100%;
  padding: 3px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(50, 50, 50, .5);
  opacity: 1;
  position: relative;
  transition: background-color .2s ease, opacity .2s ease;
}

.dropdown.active .dropdown__open {
  opacity: 0;
  outline: none;
}

.dropdown__open:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.dropdown__open:hover {
  background-color: #EAEAEA;
}

.dropdown__open:active {
  background-color: rgba(234, 234, 234, 0.3);
  outline: none;
}

.dropdown__close {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 3px;
  top: 0;
  left: 0;
  background-color: rgba(234, 234, 234, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, background-color .2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown__close:focus {
  outline: 3px solid #E8E8E8;
  outline-offset: -3px;
}

.dropdown__close:hover {
  background-color: rgba(234, 234, 234, 1);
}

.dropdown__close:active {
  background-color: rgba(234, 234, 234, 0.3);
  outline: none;
}

.dropdown.active .dropdown__close {
  opacity: 1;
  visibility: visible;
}

.dropdown__menu {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease;
}

.dropdown__menu-content {
  padding: 15px 10px;
  min-width: 294px;
  background-color: #F8F8F8;
}

.languages__full-content {
  padding: 15px 10px 10px 10px;
  min-width: 294px;
  background-color: #F8F8F8;
}

.dropdown.active .dropdown__menu {
  opacity: 1;
  visibility: visible;
}

.dropdown__title {
  padding: 16.5px 0;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #454545;
  margin-bottom: 10px;
}

.dropdown__navigation {
  padding: 0 0 10px;
}

.dropdown__item {
  margin-bottom: 10px;
}

.dropdown__item:last-child {
  margin-bottom: 0;
}

.dropdown__link {
  padding: 15px 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #323232;
  display: flex;
}

.dropdown__action {
  display: flex;
  align-items: center;
  padding: 8px 7.5px;
  background: linear-gradient(136.35deg, #F5F5F5 0%, #E6E6E6 99.27%);
  justify-content: center;
  margin: 0 -10px -10px -10px;
}

.dropdown__action .header__sign-up {
  min-height: 24px;
  min-width: 128px;
  margin-right: 0;
  padding: 0 5px;
}

.dropdown__action .header__button-text {
  font-size: 12px;
  line-height: 16px;
}

.dropdown__action-text {
  font-size: 13px;
  line-height: 18px;
  color: #454545;
  margin-right: 3px;
}

.dropdown__background {
  display: none;
}

.dropdown__action .header__button-subscribe {
  padding: 0 8.5px;
  min-width: 128px;
  min-height: 24px;
  margin-right: 0;
}

.dropdown__action .header__subscribe-text {
  font-size: 12px;
  line-height: 16px;
}

.header-notifications__link {
  padding: 10px;
  width: 100%;
  display: flex;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #454545;
  transition: background-color .2s ease;
  align-items: center;
  justify-content: space-between;
}

.header-notifications__link:hover {
  background-color: #EAEAEA;
}

.header-notifications__link:active {
  background-color: rgba(234, 234, 234, 0.3);
}

.dropdown__action {
  display: none;
}

.header > .dropdown__action {
  margin: 0;
}

.header__log-in_mobile {
  display: none;
}

@media (max-width: 1700px) {
  .header__navigation {
    position: initial;
    margin-right: auto;
    transform: translate(0);
    display: flex;
    align-items: center;
    align-self: stretch;
    height: auto;
  }
}

@media (max-width: 1500px) {
  .navigation__link {
    padding: 3px 10px;
    font-size: 15px;
  }

  .navigation__link.active::before {
    width: calc(100% - 20px);
  }
}

@media (max-width: 1200px) {
  .header__log-in_desktop {
    display: none;
  }

  .header__log-in_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header__log-in::after {
    display: none;
  }

  .header__languages {
    display: none;
  }

  .header__sign-up {
    display: none;
  }

  .header__subscribe {
    display: none;
  }

  .dropdown__action .header__sign-up {
    display: flex;
  }

  .dropdown__action .header__subscribe {
    display: flex;
  }

  .dropdown__action {
    display: flex;
  }
}

@media (max-width: 991px) {
  .header__container {
    padding: 0;
  }

  .header__log-in {
    font-size: 14px;
    padding: 3px 15px;
  }

  .languages__open {
    font-size: 14px;
    padding: 3px 15px;
  }

  .header__logo-img {
    width: 156px;
    height: 51px;
    margin-left: 8px;
  }

  .dropdown__menu {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: unset;
    overflow: auto;
    padding-bottom: 80px;
  }

  .languages__full {
    overflow: auto;
    padding-bottom: 80px;
  }

  .header__settings-full {
    overflow: auto;
    padding-bottom: 80px;
  }

  .dropdown__menu-content {
    position: relative;
    z-index: 500;
  }

  .languages__full-content {
    position: relative;
    z-index: 500;
  }

  .header__settings-content {
    position: relative;
    z-index: 500;
  }

  .languages__full {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 80px;
    left: 0;
    min-width: unset;
  }

  .header__settings-full {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 80px;
    left: 0;
    min-width: unset;
  }

  .dropdown__background {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 500;
    padding-bottom: 120px;
  }

  .languages__full {
    border: none;
  }

  .header__account {
    padding: 3px 15px;
  }

  .header__account-name {
    display: none;
  }

  .header__settings-open {
    padding: 3px 15px;
  }
}

@media (max-width: 575px) {

  .menu-burger {
    display: block;
  }

  .header__navigation {
    display: none;
  }

  .header__logo {
    margin: auto;
  }
}
</style>
